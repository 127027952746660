import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


import { tap , map, Observable} from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthService } from 'src/app/api/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {

  validation = new JwtHelperService();

  constructor(
    private auth: AuthService,
    protected storage : StorageMap,
    private router: Router,
    private sessionsServ : NgxPermissionsService,
    // private socialAuth: SocialAuthService
    ) {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate() {
    return this.storage.get('token-x').pipe(
      map((token:any)=>{
        if(token){

          let isExipred = this.validation.isTokenExpired(token);

          if(isExipred){
            this.auth.logout_end_session();
          } else {
            this.auth.token = token;
          }
          return true;

        } else {
          // this.authService.removeSession();
          this.auth.token = false;
          this.router.navigate(['authentication/login']);
          return false
        }
      })
    )
  }

}


@Injectable({
  providedIn: 'root',
})
export class LoginGuard  {
  constructor(
    protected storage : StorageMap,
    private router: Router,
    ) {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate() {
    return this.storage.get('token-x').pipe(
      map(data=>{
        if(data){
          this.router.navigate(['u/dashboard']);
          return false;
        } else {
          return true;
        }
      })
    )
  }

}
