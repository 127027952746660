import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Impor PrimeNG Modules
import { CardModule } from 'primeng/card';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast'; // Tambahkan modul Toast
import { SidebarModule } from 'primeng/sidebar';
import { BadgeModule } from 'primeng/badge';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RippleModule } from 'primeng/ripple';
import { MenubarModule } from 'primeng/menubar';
import { TableModule } from 'primeng/table';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToolbarModule } from 'primeng/toolbar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { CalendarModule } from 'primeng/calendar';
import { PaginatorModule } from 'primeng/paginator';
import { MultiSelectModule } from 'primeng/multiselect';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TabViewModule } from 'primeng/tabview';
import { RatingModule } from 'primeng/rating';
import { EditorModule } from 'primeng/editor';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { FloatLabelModule } from 'primeng/floatlabel';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';


@NgModule({
  imports: [
    CommonModule,
    CardModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    CheckboxModule,
    ToastModule,
    SidebarModule,
    BadgeModule,
    RadioButtonModule,
    InputSwitchModule,
    RippleModule,
    MenubarModule,
    TableModule,
    BreadcrumbModule,
    AutoCompleteModule,
    ToolbarModule,
    DialogModule,
    DropdownModule,
    DynamicDialogModule,
    CalendarModule,
    PaginatorModule,
    MultiSelectModule,
    SplitButtonModule,
    TabViewModule,
    RatingModule,
    EditorModule,
    ScrollPanelModule,
    FloatLabelModule,
    ToggleButtonModule,
    OverlayPanelModule
  ],
  exports:[
    CardModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    CheckboxModule,
    ToastModule,
    SidebarModule,
    BadgeModule,
    RadioButtonModule,
    InputSwitchModule,
    RippleModule,
    MenubarModule,
    TableModule,
    BreadcrumbModule,
    AutoCompleteModule,
    ToolbarModule,
    DialogModule,
    DropdownModule,
    DynamicDialogModule,
    CalendarModule,
    PaginatorModule,
    MultiSelectModule,
    SplitButtonModule,
    TabViewModule,
    RatingModule,
    EditorModule,
    ScrollPanelModule,
    FloatLabelModule,
    ToggleButtonModule,
    OverlayPanelModule
  ],
  declarations: []
})
export class PrimengModule { }
