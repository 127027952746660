<div class="flex gap-3">
  @for (item of actions; track $index) {
    <button class="bg-[#3D489C] hover:bg-blue-700 text-white font-bold py-3 px-10 rounded-full text-[18px] inline-flex items-center cursor-pointer" (click)="action(item.slug)">
      @if (!!item.iconCustom && item.iconCustom !== '') {
        <img src="{{ item.iconCustom }}" alt="{{ item.name }}" class="mr-1" />
      }
      @if (!!item.icon && item.icon !== '') {
        <i class="pi {{ item.icon }} mr-1"></i>
      }
      <span>{{ item.name }}</span>
    </button>
  }
</div>
