import { Injectable, signal } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';

import { StorageMap } from '@ngx-pwa/local-storage';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Users } from './auth.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token:any

  currentPath:any;

  public user = new BehaviorSubject<any>(null)
  users$ = this.user.asObservable()

  users!:Users;

  constructor(
    private http: HttpClient,
    private storage: StorageMap
  ) {

  }

  validationToken(){
    const helper = new JwtHelperService();
  }



  login(data:any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
    return lastValueFrom(this.http.post<any>(`${environment.apiUrl}/cms/auth/login`, data, { headers }));
  }

  loginAdfs() {
    const headers = new HttpHeaders({
      'AppID': '901c456c0835e8ac624e955c81fa01ae'
    });
    const isLocal = window.location.hostname === 'localhost';

    let param = new HttpParams({
      fromObject: {
        'relayState': `https://cms-mie2024.unictive.net/authentication/login${isLocal ? '/dev' : ''}`,
        'tokenDuration': '3600',
      }
    });
    return lastValueFrom(this.http.get<any>(`${environment.apiUrladfs}/adfs/auth`+(param ? '?'+param : ''), { headers: headers }));
  }

  GET_UserData(){
    // lastValueFrom(this.http.get<any>(`${environment.apiUrl}/cms/v1/am/admin/info`)).then(res=>{
    lastValueFrom(this.http.get<any>(`${environment.apiUrl}/cms/auth/info`)).then(res=>{
      if(res.success){
        this.user.next(res.data);
        lastValueFrom(this.storage.set('users', res.data));
      }
    })
  }

  GET_UserDataAdfs(token: string){
    const headers = new HttpHeaders({
      'AppID': '901c456c0835e8ac624e955c81fa01ae',
      'Authorization': `Bearer ${token}` // Assuming you have a method to get the token
    });
    return lastValueFrom(this.http.get<any>(`${environment.apiUrladfs}/adfs/user-details`, { headers: headers }));
  }

  userdata(){
    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/cms/v1/am/admin/info`)).then(res=>{
      if(res.success){
        this.user.next(res.data);
        return res.data;
      }
    })
  }

  // validateToken(): Observable<boolean> {
  //   const validateTokenUrl = `${environment.apiUrl}/auth/validate-token`; // Replace with your actual API endpoint
  //   const headers = new HttpHeaders({
  //     'Authorization': `Bearer ${this.token}` // Assuming you have a method to get the token
  //   });

  //   return this.http.get<boolean>(validateTokenUrl, { headers });
  // }


  logout(){
    lastValueFrom( this.storage.delete('token-x'));
    // lastValueFrom( this.storage.delete('users'));
    // lastValueFrom( this.storage.delete('property'));
    // this.sesServ.removeRole();
      // this.navbar(true);
      setTimeout(()=>{
          window.location.reload();
        },1000);

  }

  logout_end_session(){
    lastValueFrom( this.storage.delete('token-x'));
    // lastValueFrom( this.storage.delete('users'));
    // lastValueFrom( this.storage.delete('property'));
    // this.sesServ.removeRole();
      // this.navbar(true);
      setTimeout(()=>{
          window.location.reload();
        },1000);

  }

  logoutAdfs(){
    this.storage.get('token-adfs').subscribe((tokenAdfs) => {
      const token = tokenAdfs as string;
      const headers = new HttpHeaders({
        'AppID': '901c456c0835e8ac624e955c81fa01ae',
        'Authorization': `Bearer ${token}`
      });
      const isLocal = window.location.hostname === 'localhost';
      let param = new HttpParams({
        fromObject: {
          'relayState': `https://cms-mie2024.unictive.net/authentication/login${isLocal ? '/dev' : ''}`,
          'tokenDuration': '3600',
        }
      });
      return lastValueFrom(this.http.get<any>(`${environment.apiUrladfs}/adfs/logout`+(param ? '?'+param : ''), { headers: headers }));
    });
  }
}
