import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() title!: string | undefined;
  @Input() hasFilter!: boolean;
  @Input() filterFields!: any[];
  @Input() hasActionBtn!: boolean;
  @Input() actionButtons!: any[];

  @Output() onSubmitFilter = new EventEmitter();
  @Output() onAction = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  filterData(data: any) {
    this.onSubmitFilter.emit(data);
  }

  runAction(data: any) {
    this.onAction.emit(data);
  }

}
