import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'list-events',
  templateUrl: './list-events.component.html',
  styleUrls: ['./list-events.component.scss']
})
export class ListEventsComponent implements OnInit, OnChanges {
  @Input() data!:any;
  @Output() deleteAction = new EventEmitter<any>();
  @Output() statusAction = new EventEmitter<any>();

  list:any[] = [];

  constructor(
    private router: Router,
    private helper: HelperService,
    readonly title: Title,
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      this.list = changes['data'].currentValue.result;
    }
  }

  onEdit(data:any) {
    this.router.navigateByUrl('/u/events/' + data.id);
  }

  onDelete(data:any) {
    this.deleteAction.emit(data);
  }

  onStatusChange(data:any) {
    this.statusAction.emit(data);
  }

  isSameDate(date1:any, date2:any) {
    return this.helper.isSameDate(date1, date2);
  }

}
