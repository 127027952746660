<div class="flex justify-between">
  <h1 class="mb-10">{{ title }}</h1>

  <div class="flex gap-3">
    <ng-container *ngIf="hasFilter">
      <!-- MARK: Filter component -->
      <app-header-filter [fields]="filterFields" (onFilter)="filterData($event)"></app-header-filter>
    </ng-container>

    <ng-container *ngIf="hasActionBtn">
      <!-- MARK: Action Button component -->
      <app-header-button-action [actions]="actionButtons" (onAction)="runAction($event)"></app-header-button-action>
    </ng-container>
  </div>
</div>
