import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrimengModule } from '../../library/primeng.module';
import { ListEventsComponent } from './list-events.component';

@NgModule({
  imports: [
    CommonModule,
    PrimengModule
  ],
  declarations: [ListEventsComponent],
  exports: [ListEventsComponent]
})
export class ListEventsModule { }
