@if (data.totalPage > 0) {
  <div class="relative">
    <div class="flex w-full justify-center">
      <p-paginator
        (onPageChange)="onPageChange($event)"
        [first]="first"
        [rows]="data.limit"
        [totalRecords]="data.totalPage"
        [showFirstLastIcon]="true">
        <ng-template pTemplate="firstpagelinkicon">
          <button type="button" class="p-paginator-first p-paginator-element p-link">First</button>
        </ng-template>
        <ng-template pTemplate="lastpagelinkicon">
          <button type="button" class="p-paginator-last p-paginator-element p-link">Last</button>
        </ng-template>
      </p-paginator>
    </div>

    @if (showPaginator) {
      <div class="absolute top-0 end-0 content-center" style="height: -webkit-fill-available;">
        <div class="flex items-center">
          <span class="mx-1 text-color">Rows per page: </span>
          <p-dropdown [options]="options" (onChange)="onLimitChange($event)" placeholder="Select" [disabled]="data.totalPage < 15" />
        </div>
      </div>
    }
  </div>
}
