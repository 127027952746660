<div class="grid grid-cols-3 gap-8">
  @if (list.length > 0) {
    @for (item of list; track $index) {
      <div class="panel">
        <p-card>
          <div class="flex flex-col justify-between min-h-[150px]">
            <div class="py-0">
              <h3 class="!line-clamp-2 !font-bold leading-normal mb-2">{{ item.name }}</h3>
              <p>
                {{ item.startedAt | date : "MMM dd, yyyy" }}
                {{
                  !isSameDate(item.startedAt, item.endedAt)
                  ? ' - ' + (item.endedAt | date : "MMM dd, yyyy")
                  : ''
                }}
              </p>
            </div>

            <div class="flex justify-between items-center">
              <div>
                <button class="bg-transparent cursor-pointer" (click)="onEdit(item)">
                  <img src="assets/icon/icon-edit.svg" alt="">
                </button>
                <button class="bg-transparent cursor-pointer" (click)="onDelete(item)">
                  <img src="assets/icon/icon-delete.svg" alt="">
                </button>
              </div>
              <p-inputSwitch [(ngModel)]="item.status" (onChange)="onStatusChange(item)" />
            </div>
          </div>
        </p-card>
      </div>
    }
  } @else {
    <div class="flex justify-center items-center col-span-3">
      <p class="text-center !text-[#919191] italic text-lg">No events available</p>
    </div>
  }
</div>
